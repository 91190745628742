<template>
  <div class="k-9sptci" v-loading="loading">
    <!-- 查询数据 -->
    <div slot class="clearfix">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="门派ID">
          <el-input
            placeholder="请输入门派ID"
            v-model="searchForm.sect_id"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="门派名称">
          <el-input
            placeholder="请输入门派名称"
            v-model="searchForm.name"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>
        <el-form-item label="当前状态">
          <el-select
            @change="handleDepot()"
            v-model="searchForm.status"
            placeholder="请选择查询条件"
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="回收站" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          @click="handleQuery()"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >查 询</el-button
        >
        <el-button
          @click="addUser"
          style="margin-left: 20px; margin-right: 20px;padding: -9px 30px; font-size: 15px"
          type="primary"
          >新增门派</el-button
        >
        <el-form-item>
          <importButton
            :import-url="`/admin/import/import_sect_alliance`"
            :status-url="`/admin/import/import_sect_alliance_status`"
            :data="{ match_id: match_id }"
            @refushList="handleQuery()"
          ></importButton>
        </el-form-item>
        <el-form-item>
          <importHistoryButton
            :history_url="`/admin/import/import_sect_alliance_history`"
            :import_template="`import_sect_alliance`"
          ></importHistoryButton>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <el-table-column
        v-for="item in dataList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑门派</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id, scope.row.status)"
            >{{ scope.row.status == 1 ? "删除" : "恢复" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="view_type == 'add' ? '新增门派' : '修改门派'"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item
          label="门派ID"
          prop="sect_id"
          :rules="[
            {
              required: true,
              message: '请输入门派ID',
              trigger: ['blur'],
            },
            {
              max: 6,
              message: '最多6个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.sect_id"
            placeholder="请输入门派ID"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="门派名称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '请输入门派名称',
              trigger: ['blur'],
            },
            {
              max: 25,
              message: '最多25个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.name"
            placeholder="请输入门派名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { MessageBox } from "element-ui";
import importButton from "../../match/components/importButton";
import importHistoryButton from "../../match/components/importHistoryButton";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  components: {
    importButton,
    importHistoryButton,
  },
  data() {
    return {
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        status: 1,
      },
      dataTable: [],
      dataList: [
        {
          prop: "sect_id",
          label: "门派ID",
          width: 300,
        },
        {
          prop: "name",
          label: "门派名称",
          width: 300,
        },
        {
          prop: "status",
          label: "当前状态",
          width: 200,
          formatter: (row) => {
            switch (row.status) {
              case 1:
                return "正常";
              case 0:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      userVisible: false,
      view_type: "add",
      userData: {
        _id: "",
        sect_id: "",
        name: "",
      },
      match: false,
    };
  },
  methods: {
    // 添加初始化
    addUser() {
      this.view_type = "add";
      this.userData._id = "";
      this.userData.sect_id = "";
      this.userData.name = "";
      this.userVisible = true;
    },
    //编辑赋值
    handleEdit(row) {
      this.view_type = "edit";
      this.userData._id = row._id;
      this.userData.sect_id = row.sect_id;
      this.userData.name = row.name;
      this.userVisible = true;
    },
    //添加banner
    saveBanner() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          let saveData = {
            _id: this.userData._id,
            sect_id: this.userData.sect_id,
            name: this.userData.name,
          };

          let { data, code } = await this.$http.operate.save_sect_alliance(
            saveData
          );
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.userVisible = false;
            this.handleQuery();
          }
        }
      });
    },
    //删除
    async handleDelete(_id, status) {
      let status_title = status == 1 ? "是否删除" : "是否恢复";
      await MessageBox.confirm(status_title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });

      let new_status = status == 0 ? 1 : 0;
      const { code } = await this.$http.operate.update_status({
        _id: _id,
        status: new_status,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.handleQuery();
      }
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.operate.sect_alliance_list({
        ...this.searchForm,
        pageNum,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    //查询列表
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
